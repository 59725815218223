<div class="min-h-screen flex flex-col py-24 sm:px-6 lg:px-8 px-6">
    <div class="sm:mx-auto sm:w-full sm:max-w-md ">
      <div class="flex justify-center">
        <img src="../../../assets/images/forgot-pssword-SVG/forgot-password-icon.svg" alt="change-password-icon" />
      </div>
      <h2 class="text-2xl font-semibold leading-3 text_gray_900 text-center mt-6">
        Set new password
      </h2>
    </div>
  
    <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="">
        <form autocomplete="off" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
          <div class="mb-8">
            <label for="password" class="form_inputbox_label">Existing Password</label>
            <div class="relative rounded-md shadow-sm">
              <mat-form-field appearance="outline" class="w-full">
                <input matInput placeholder="Enter your existing password" aria-label="existing-password" formControlName="existingPassword" [type]="type" required
                  class="form_inputbox" />
                <mat-icon matSuffix
                  *ngIf="changePasswordForm.get('existingPassword').touched && changePasswordForm.get('existingPassword').invalid"
                  class="mat-error-icon mat_icon">error_outline</mat-icon>
                <button matSuffix mat-icon-button type="button"
                  *ngIf="changePasswordForm.get('existingPassword').untouched || changePasswordForm.get('existingPassword').valid">
                  <mat-icon *ngIf="type=='password'" class="mat_icon" (click)="type='text'">visibility</mat-icon>
                  <mat-icon *ngIf="type=='text'" class="mat_icon" (click)="type='password'">visibility_off</mat-icon>
                </button>
                <mat-error
                  *ngIf="changePasswordForm.get('existingPassword').touched && changePasswordForm.get('existingPassword').hasError('required')">
                  Please enter the existing password
                </mat-error>
                <mat-error
                  *ngIf="!changePasswordForm.get('existingPassword').hasError('required') && changePasswordForm.get('existingPassword').hasError('existingPassInvalid')">
                  Entered password does not match with existing password.
                </mat-error>
                
              </mat-form-field>
            </div>
          </div>
          <div class="mb-10">
            <label for="newPassword" class="form_inputbox_label">New Password</label>
            <div class="relative rounded-md shadow-sm">
              <mat-form-field appearance="outline" class="w-full">
                <input matInput placeholder="Enter your new password" aria-label="newpassword" formControlName="newPassword" [type]="newType"
                  required class="form_inputbox" />
                <mat-icon matSuffix
                  *ngIf="changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').invalid"
                  class="mat-error-icon mat_icon">error_outline</mat-icon>
                <button matSuffix mat-icon-button type="button"
                  *ngIf="changePasswordForm.get('newPassword').untouched || changePasswordForm.get('newPassword').valid">
                  <mat-icon *ngIf="newType=='password'" class="mat_icon" (click)="newType='text'">visibility</mat-icon>
                  <mat-icon *ngIf="newType=='text'" class="mat_icon" (click)="newType='password'">visibility_off</mat-icon>
                </button>
                <mat-hint *ngIf="changePasswordForm.get('newPassword').invalid">
                  The new password must be 14-36 characters long and include at least one letter, one number, and one special character.
                </mat-hint>
                <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">
                  Please enter a new password</mat-error>
                <mat-error *ngIf="!changePasswordForm.get('newPassword').hasError('required') && changePasswordForm.get('newPassword').hasError('invalidMatchPassword')">
                  Passwords do not match</mat-error>
                  <mat-error *ngIf="!changePasswordForm.get('newPassword').hasError('required') && changePasswordForm.get('newPassword').hasError('maxlength')">New password must not exceed 36 characters in length.
                  </mat-error>
                  <mat-error *ngIf="!changePasswordForm.get('newPassword').hasError('required') && changePasswordForm.get('newPassword').hasError('minlength')">New password must be at least 14 characters long.
                  </mat-error>
                  <mat-error *ngIf="!changePasswordForm.get('newPassword').hasError('minlength') && !changePasswordForm.get('newPassword').hasError('maxlength') && changePasswordForm.get('newPassword').hasError('pattern')">The new password must be 14-36 characters long and include at least one letter, one number, and one special character.
                  </mat-error>
                  <mat-error *ngIf="!changePasswordForm.get('newPassword').hasError('required') && changePasswordForm.get('newPassword').hasError('newPasswordSameAsCurrent')">New password is the same as your current password, please change it.
                  </mat-error>
                  <mat-error *ngIf="!changePasswordForm.get('newPassword').hasError('required') && changePasswordForm.get('newPassword').hasError('newPassUsedRecently')">New password has been used already, please choose another.
                  </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="mb-8">
            <label for="confirmPassword" class="form_inputbox_label">Confirmed Password</label>
            <div class="relative rounded-md shadow-sm">
              <mat-form-field appearance="outline" class="w-full">
                <input matInput placeholder="Enter your confirmed password" aria-label="cofirmpassword" formControlName="confirmPassword" [type]="confirmType" required
                  class="form_inputbox" />
                <mat-icon matSuffix
                  *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').invalid"
                  class="mat-error-icon mat_icon">error_outline</mat-icon>
                <button matSuffix mat-icon-button type="button"
                  *ngIf="changePasswordForm.get('confirmPassword').untouched || changePasswordForm.get('confirmPassword').valid">
                  <mat-icon *ngIf="confirmType=='password'" class="mat_icon" (click)="confirmType='text'">visibility</mat-icon>
                  <mat-icon *ngIf="confirmType=='text'" class="mat_icon" (click)="confirmType='password'">visibility_off</mat-icon>
                </button>
                <mat-error
                  *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').hasError('required')">
                  Please enter a confirmed password
                </mat-error>
                <mat-error *ngIf="!changePasswordForm.get('confirmPassword').hasError('required') && changePasswordForm.get('confirmPassword').hasError('maxlength')">Confirmed password must not exceed 36 characters in length.
                </mat-error>
                <mat-error *ngIf="!changePasswordForm.get('confirmPassword').hasError('required') && changePasswordForm.get('confirmPassword').hasError('minlength')">Confirmed password must be at least 14 characters long.
                </mat-error>
                <mat-error *ngIf="!changePasswordForm.get('confirmPassword').hasError('required') && changePasswordForm.get('confirmPassword').hasError('newPasswordSameAsCurrent')">Confirmed password is the same as your current password, please change it.
                </mat-error>
                <mat-error *ngIf="!changePasswordForm.get('confirmPassword').hasError('required') && changePasswordForm.get('confirmPassword').hasError('newPassUsedRecently')">Confirmed password has been used already, please choose another.
                </mat-error>
                <mat-error *ngIf="!changePasswordForm.get('confirmPassword').hasError('required') && !changePasswordForm.get('confirmPassword').hasError('minlength') && !changePasswordForm.get('confirmPassword').hasError('maxlength') && changePasswordForm.get('confirmPassword').hasError('invalidMatchPassword')">
                  New password and confirmed password do not match</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="mt-12">
              <button type="submit" [disabled]="!changePasswordForm.valid" class="form_button" [class.form_button_disable]="!changePasswordForm.valid">
                Set Password
              </button>
          </div>
         </form>
      </div>
    </div>
  </div>
  